//npm i react-toggle-dark-mode
//darkMode: "class",
//useDarkSide
import React, { useState } from "react";
import useDarkSide from "./useDarkSide";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  Fade,
  Grid,
  Grow,
  Paper,
  Popper,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Check, Close, Logout, VpnKey } from "@mui/icons-material";
import unsplash from "../api/unsplash";
import Slide from "@mui/material/Slide";
import GlobalSnackbar from "../components/Snackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Switcher = () => {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState();
  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showNewDup, setShowNewDup] = useState(false);
  const [openPsw, setOpenPsw] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    user: "",
    password: "",
    newPassword: "",
    newPasswordDup: "",
  });

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  const handleCloseButton = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    let personaleData = { ...data };
    personaleData[e.target.name] = e.target.value;
    setData(personaleData);
  };

  const handleClickPopper = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClickOpen = () => {
    setOpenPsw(true);
  };

  const handleClose = () => {
    setData({
      user: "",
      password: "",
      newPassword: "",
      newPasswordDup: "",
    });
    setOpenPsw(false);
  };

  const logout = () => {
    sessionStorage.removeItem("logged");
    localStorage.removeItem("fotoProfilo");
    navigate("/login");
  };

  const handleCambioPassword = async (usr) => {
    if (usr.newPassword === usr.newPasswordDup) {
      let controlloPsw;
      try {
        controlloPsw = await unsplash.post("login", {
          username: usr.user,
          password: usr.password,
          tipologia: "Clienti",
        });
      } catch (error) {}
      if (controlloPsw?.data?.utente?.data?.length > 0) {
        try {
          await unsplash.post(
            "cambiaPsw",
            {
              username: usr.user,
              password: usr.newPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          logout();
        } catch (error) {}
      } else {
        setSnack({
          severity: "error",
          messaggio: "Password errata, riprovare!",
        });
        setOpenSnack(true);
        setData({
          user: "",
          password: "",
          newPassword: "",
          newPasswordDup: "",
        });
      }
    } else {
      setSnack({
        severity: "error",
        messaggio: "Le password non coincidono!",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="md:hidden block">
        <AccountCircle
          className={`absolute right-20 top-6 z-50 cursor-pointer ${
            darkSide ? "text-white" : "text-black"
          }`}
          onClick={handleClickPopper("bottom")}
          fontSize={"large"}
        />
      </div>
      <DarkModeSwitch
        className="absolute right-5 top-6 z-50"
        checked={darkSide}
        onChange={toggleDarkMode}
        size={30}
      />
      <Popper
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseButton}>
                <Card
                  sx={{
                    //bgcolor: theme.palette.primary,
                    my: 0,
                  }}
                >
                  <CardContent>
                    <Grid container spacing={3} direction="column">
                      <Grid item>
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item xs={12} sx={{ marginTop: "10px" }}>
                            <Button
                              startIcon={<VpnKey />}
                              className="ButtonMiller"
                              fullWidth
                              variant="contained"
                              onClick={handleClickOpen}
                            >
                              Cambia Password
                            </Button>
                          </Grid>
                          <Grid item xs={12} sx={{ marginTop: "10px" }}>
                            <Button
                              startIcon={<Logout />}
                              className="ButtonMiller"
                              fullWidth
                              variant="contained"
                              onClick={logout}
                            >
                              Log out
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Dialog
        open={openPsw}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={"sm"}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="text-center">Modifica Password</DialogTitle>
        <DialogContent className="space-y-4 md:space-y-6 my-2">
          <div className="p-2 space-y-4 md:space-y-6">
            <div className="relative">
              <span className="absolute right-0 flex items-center pr-2 h-full">
                <button
                  type="button"
                  onClick={() => setShowOld(!showOld)}
                  className="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    hidden={!showOld}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                    <path
                      fillRule="evenodd"
                      d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <svg
                    hidden={showOld}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                    <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                    <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                  </svg>
                </button>
              </span>
              <input
                type={showOld ? "text" : "password"}
                name="password"
                id="password"
                className="w-full py-4 px-8 bg-white rounded-md ring-1 outline-primary-600 ring-primary-600"
                placeholder="Vecchia Password"
                onChange={(e) => handleChange(e)}
                value={data.password}
              />
            </div>
            <div className="flex gap-6">
              <div className="relative">
                <span className="absolute right-0 flex items-center pr-2 h-full">
                  <button
                    type="button"
                    onClick={() => setShowNew(!showNew)}
                    className="p-1 focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      hidden={!showNew}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                      <path
                        fillRule="evenodd"
                        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <svg
                      hidden={showNew}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                    </svg>
                  </button>
                </span>
                <input
                  type={showNew ? "text" : "password"}
                  name="newPassword"
                  id="password"
                  className="w-full py-4 px-8 bg-white rounded-md ring-1 outline-primary-600 ring-primary-600"
                  placeholder="Nuova Password"
                  onChange={(e) => handleChange(e)}
                  value={data.newPassword}
                />
              </div>
              <div className="relative">
                <span className="absolute right-0 flex items-center pr-2 h-full">
                  <button
                    type="button"
                    onClick={() => setShowNewDup(!showNewDup)}
                    className="p-1 focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      hidden={!showNewDup}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                      <path
                        fillRule="evenodd"
                        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <svg
                      hidden={showNewDup}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                    </svg>
                  </button>
                </span>
                <input
                  type={showNewDup ? "text" : "password"}
                  name="newPasswordDup"
                  id="password"
                  className="w-full py-4 px-8 bg-white rounded-md ring-1 outline-primary-600 ring-primary-600"
                  placeholder="Ripeti Password"
                  onChange={(e) => handleChange(e)}
                  value={data.newPasswordDup}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <div className="flex justify-between px-8 mb-4">
          <div className="w-fit">
            <Button
              onClick={handleClose}
              startIcon={<Close />}
              className="ButtonMiller"
              fullWidth
              variant="contained"
            >
              CHIUDI
            </Button>
          </div>
          <div className="w-fit">
            <Button
              onClick={() => {
                handleCambioPassword({
                  ...data,
                  user: sessionStorage.getItem("User"),
                });
              }}
              startIcon={<Check />}
              className="ButtonMiller"
              fullWidth
              variant="contained"
            >
              CONFERMA
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
