import React, { useEffect, useState } from "react";
import ClippedDrawer from "./AppContent";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  itIT,
} from "@mui/x-data-grid-pro";
import {
  IconButton,
  Tooltip,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  LinearProgress,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import {
  Download,
  RemoveRedEye,
  Delete,
  Close,
  Check,
} from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import moment from "moment";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const dataHeaders = [{ label: "Descrizione" }, { label: "Dimensione" }];

const Documenti = () => {
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [filterClienti, setFilterClienti] = useState([]);
  const columns = [
    {
      field: "Azioni",
      headerName: "Azioni",
      hide: false,
      filterable: false,
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() =>
              downloadFile(
                params.row.ID,
                params.row.Estensione,
                params.row.Descrizione,
                "download"
              )
            }
          >
            <Download
              style={{ height: "2rem", width: "2rem", color: "#11526f" }}
            />
          </IconButton>
          {params.row.Estensione === ".pdf" ? (
            <IconButton
              aria-label="edit"
              onClick={() =>
                downloadFile(
                  params.row.ID,
                  params.row.Estensione,
                  params.row.Descrizione,
                  "visualizza"
                )
              }
            >
              <RemoveRedEye
                style={{ height: "2rem", width: "2rem", color: "#11526f" }}
              />
            </IconButton>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      field: "RagioneSociale",
      headerName: "Ragione Sociale",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const [allegati, setAllegati] = useState([]);
  const [allegatiBk, setAllegatiBk] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let res = await unsplash.get("utentiClienti", {
          //ricarico i valori
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            utente: sessionStorage.getItem("User"),
          },
        });

        let idResult = [];
        for (let index = 0; index < res.data.data.length; index++) {
          const cliente = res.data.data[index];

          idResult.push(cliente.Cliente);
        }
        let responseAziendali = await unsplash.get("allegati", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { colonna: "ClienteWeb", ID: idResult },
        });

        if (sessionStorage.getItem("Dettaglio") === "1") {
          setAllegati(
            responseAziendali.data.data.filter(
              (v) => sessionStorage.getItem("Email") === v.EmailReferente
            )
          );
          setAllegatiBk(
            responseAziendali.data.data.filter(
              (v) => sessionStorage.getItem("Email") === v.EmailReferente
            )
          );
          const ragSoc = responseAziendali.data.data.filter(
            (v) => sessionStorage.getItem("Email") === v.EmailReferente
          ).map(
            (el) => el.RagioneSociale
          );
          const a = new Set(ragSoc);
          setFilterClienti([...a]);
        } else {
          setAllegati(responseAziendali.data.data);
          setAllegatiBk(responseAziendali.data.data);
          const ragSoc = responseAziendali.data.data.map(
            (el) => el.RagioneSociale
          );
          const a = new Set(ragSoc);
          setFilterClienti([...a]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      if (loadingDoc) {
        alert(
          "Aspetta che la richiesta precedente sia completata prima di iniziare"
        );
      } else {
        setLoadingDoc(true);
        // Make the POST request to trigger the download and response from the server
        const response = await unsplash.post(
          "ftp",
          {
            file: id + estensione,
            nomeOriginale: descrizione.includes(estensione)
              ? descrizione
              : descrizione + estensione,
            operazione: "download",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: ext[estensione.replace(".", "")] })
        );
        const link = document.createElement("a");
        link.href = url;
        if (operazione === "download") {
          link.setAttribute(
            "download",
            descrizione.includes(estensione)
              ? descrizione
              : descrizione + estensione
          );
          document.body.appendChild(link);
          link.click();
        }

        if (operazione === "visualizza") {
          if (estensione === ".pdf") {
            window.open(url);
          }
        }
        setLoadingDoc(false);
      }
    } catch (error) {
      let messaggio = "Errore durante la visualizzazione del file: ";
      if (operazione === "download") {
        messaggio = "Errore durante il download del file: ";
      }
      alert(messaggio);
      if (error.response) {
        if (error.response.status === 404) {
          messaggio = messaggio + "File non trovato.";
        } else if (error.response.status === 500) {
          messaggio =
            messaggio +
            "Errore interno del server. Si prega di riprovare più tardi.";
        } else {
          messaggio = messaggio + "Errore sconosciuto.";
        }
      } else if (error.request) {
        // La richiesta è stata effettuata ma non è stata ricevuta alcuna risposta
        messaggio =
          messaggio +
          "Nessuna risposta ricevuta dal server. Si prega di controllare la connessione a Internet.";
      } else {
        // Si è verificato qualche altro errore
        messaggio =
          messaggio +
          "Si è verificato un errore. Si prega di riprovare più tardi.";
      }
      alert(messaggio);
      console.error("Errore durante il download del file:", error);
      setLoadingDoc(false);
    }
  };

  function CustomToolbar() {
    return (
      <div className="flex flex-wrap justify-center md:justify-start my-2">
        <GridToolbarContainer sx={{ display: "flex", flexWrap: "wrap" }}>
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <div className=" flex flex-wrap flex-1 h-screen bg-white p-4  shadow-lg rounded-md border border-slate-200">
      <div className="flex w-full items-center justify-between flex-wrap md:flex-nowrap gap-4">
        <div className="w-full md:w-1/2 flex gap-4 flex-wrap md:flex-nowrap">
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={filterClienti}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              if (newValue.length > 0)
                return setAllegati(
                  allegatiBk.filter((el) =>
                    newValue.includes(el.RagioneSociale)
                  )
                );
              return setAllegati(allegatiBk);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            size="medium"
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Clienti"
                placeholder="Ragioni Sociali"
              />
            )}
          />
        </div>
      </div>
      <div className="h-[70vh] md:h-[80vh] w-full">
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={allegati}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.ID}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};

export default Documenti;
