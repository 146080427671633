import "../App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Home from "./Home";
import AppContent from "./AppContent";
import Lavoratori from "./Lavoratori";
import Medicina from "./Medicina";
import Corsi from "./Corsi";
import Documenti from "./Documenti";
import Sopralluoghi from "./Sopralluoghi";
import PrivateRoute from "./PrivateRoute";
import "react-toastify/dist/ReactToastify.css";
import { LicenseInfo } from "@mui/x-data-grid-pro";

import "../style/style.css";
import { useEffect } from "react";
import VisiteMediche from "./VisiteMediche";
import IdoneitaAllegate from "./IdoneitaAllegate";
import LoginMedico from "./LoginMedico";
import HomepageMedico from "./HomepageMedico";
import HelpPage from "./HelpPage";



function App() {
  LicenseInfo.setLicenseKey(
    "7de2641e5b9a08694469a2d7eb4ed7dbTz0xMDE1ODAsRT0xNzY3NTMyNDQ1MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
    //"bbfa42661755c336eeead7dc49a7dd92Tz01ODM1NCxFPTE3MDYxODI3MDY0MzIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login-medico" element={<LoginMedico />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <AppContent />
              </PrivateRoute>
            }
          >
            <Route
              path="/ScadenzarioVisite"
              element={
                <PrivateRoute>
                  <Lavoratori />
                </PrivateRoute>
              }
            />
            <Route
              path="/DashboardMedico"
              element={
                <PrivateRoute>
                  <HomepageMedico />
                </PrivateRoute>
              }
            />
             <Route
              path="/Dashboard"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/corsi"
              element={
                <PrivateRoute>
                  <Corsi />
                </PrivateRoute>
              }
            />
            <Route
              path="/Documenti"
              element={
                <PrivateRoute>
                  <Documenti />
                </PrivateRoute>
              }
            />
            <Route
              path="/Help"
              element={
                <PrivateRoute>
                  <HelpPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/sopralluoghi"
              element={
                <PrivateRoute>
                  <Sopralluoghi />
                </PrivateRoute>
              }
            />
            <Route
              path="/visiteMediche"
              element={
                <PrivateRoute>
                  <VisiteMediche />
                </PrivateRoute>
              }
            />
            {/* <Route path="/idoneitaStop" element={<PrivateRoute><IdoneitaAllegate /></PrivateRoute>} />*/}
            <Route
              path="/idoneita"
              element={
                <PrivateRoute>
                  <Medicina />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

/*


import "../App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./AccessoCliente/Login";
import Home from "./AccessoCliente/Home";
import AppContent from "./AccessoCliente/AppContent";
import Lavoratori from "./AccessoCliente/Lavoratori";
import Medicina from "./AccessoCliente/Medicina";
import Corsi from "./AccessoCliente/Corsi";
import Documenti from "./AccessoCliente/Documenti";
import Sopralluoghi from "./AccessoCliente/Sopralluoghi";
import PrivateRoute from "./AccessoCliente/PrivateRoute";
import "react-toastify/dist/ReactToastify.css";
import { LicenseInfo } from "@mui/x-data-grid-pro";

import "../style/style.css";
import { useEffect } from "react";
import VisiteMediche from "./AccessoCliente/VisiteMediche";
import IdoneitaAllegate from "./AccessoCliente/IdoneitaAllegate";
import LoginMedico from "./AccessoCliente/LoginMedico";
import HomepageMedico from "./AccessoCliente/HomepageMedico";
import HelpPage from "./AccessoCliente/HelpPage";

*/