import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React,{useState} from "react";

import { toast, ToastContainer } from "react-toastify";
import unsplash from "../api/unsplash";


const HelpPage =() => {
const [text,setText] = useState("")



const handleHelp = async() => {


try{
    const response = await unsplash.post("help", {
        text: text,
        email:"federico.barenghi@millergroup.it",
        cliente:sessionStorage.getItem("User")
      
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
      
      );

      toast.success("GRAZIE PER AVER INVIATO LA RICHIESTA, RICEVERAI SUPPORTO IL PRIMA POSSIBILE",{autoClose: 5000,});
      setText("")
    }catch{
        toast.error("NON E' STATO POSSIBILE INVIARE LA RICHIESTA DI SUPPORTO",{autoClose: 5000,});
    }



}











return (   <div className="flex flex-wrap ">
<div className=" bg-primary-600 dark:bg-white p-4 sm:p-7 rounded-md h-fit w-full">
  <ToastContainer
    position="top-right"
    autoClose={2000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    draggable
    pauseOnHover
    theme="colored"
  />
  {/* Background illustration */}

  {/* Content */}
  <div className="relative">
    {/* <span className="absolute inset-y-0 right-0 top-0 pointer-events-none z-50 hidden xl:block h-20 w-20 light-logo"></span> */}
   
    <h1 className="text-2xl md:text-3xl text-white dark:text-primary-600 font-bold mb-1">
      Hai bisogno di assistenza?
    </h1>
    <p className="text-white dark:text-primary-600 w-5/6 ">
      Richiedi supporto sulle funzionalità della piattaforma selezionando la categoria di riferimento. Verrai ricontattato il prima possibile da un referente.
    </p>
  </div>
</div>
<div className="bg-white p-4 sm:p-7 rounded-md mt-4 flex flex-col h-fit w-full">
<div><FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    //id="demo-simple-select"
    //value={age}
    label="Categoria"
    //onChange={handleChange}
  >
    <MenuItem value={"Medicina"}>Medicina</MenuItem>
    <MenuItem value={"Sicurezza"}>Sicurezza</MenuItem>
    <MenuItem value={"Formazione"}>Formazione</MenuItem>
    {/*<MenuItem value={"Bug"}>Bug Piattaforma</MenuItem>*/}
  </Select>
</FormControl></div>

<label for="message" className="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 font-bold">La tua richiesta</label>
    <textarea value={text}  onChange={(e) => setText(e.target.value)}  id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Scrivi qui la tua richiesta..."></textarea>
  <div className="flex flex-row-reverse">
    <button
           onClick={() => handleHelp("parametro")}
           className="mt-5  transition-all hover:bg-transparent bg-primary-500 hover:text-primary-700 text-white px-2 border hover:border-primary-500 border-transparent rounded  py-[8.5px] text-[1.1em]"
         >
         Invia
         </button>
         </div>
</div>

{/*
<div className="grid grid-cols-12 gap-3 mt-4">
  
  {
    sessionStorage.getItem("M") === "1" ? (
      
      <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white rounded-md border border-slate-200">
        <div className="px-5 pt-5">
          <header className="flex justify-between items-start mb-2">
           
            Medicina
          
            <Circle
              className="relative inline-flex"
              sx={{ color: "#51E838" }}
            />
          </header>
          <h2 className="text-lg font-semibold text-slate-800 mb-2">
            % Lavoratori Visitati
          </h2>
          <div className="flex justify-center mb-8 mt-8">
            <CircularProgressWithLabel
              variant="determinate"
              value={valMedicina}
              size={150}
            />
          </div>
        </div>
        
        <div className="grow">
          
        </div>
      </div>
    ) : (
      <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-grey hover:bg-white hover:cursor-pointer transition duration-300  rounded-md border border-slate-200">
        <div className="px-5 pt-5">
          <header className="flex justify-between items-start mb-2">
            
            Medicina
            
            <Circle
              className="relative inline-flex"
              sx={{ color: "#ff5757" }}
            />
          </header>
          <h2 className="text-lg font-semibold text-slate-800 mb-2">
            Pacchetto non Acquistato{" "}
          </h2>
          <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
            Vuoi acquistare questo pacchetto?
          </div>
          <div className="flex items-start">
            <button
              className="w-full m-5 p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
              id="Locker"
              onClick={() => askForArea("Medicina")}
            >
              <Lock id="LockerUP" />
              SBLOCCA{" "}
            </button>
          </div>
        </div>
        
        <div className="grow">
         
        </div>
      </div>
    )

    
  }
  
  {
    sessionStorage.getItem("S") === "1" ? (
      
      <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white  rounded-md border border-slate-200">
        <div className="px-5 pt-5">
          <header className="flex justify-between items-start mb-2">
           
            Sicurezza
            
            <Circle
              className="relative inline-flex"
              sx={{ color: "#51E838" }}
            />
          </header>
          <h2 className="text-lg font-semibold text-slate-800 mb-2">
            % Sicurezza
          </h2>
          <div className="flex justify-center mb-8 mt-8">
            <CircularProgressWithLabel
              variant="determinate"
              value={valSicurezza}
              size={150}
            />
          </div>
        </div>
       
        <div className="grow">
          
        </div>
      </div>
    ) : (
      <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-grey hover:bg-white hover:cursor-pointer transition duration-300   rounded-md border border-slate-200">
        <div className="px-5 pt-5">
          <header className="flex justify-between items-start mb-2">
            
            Sicurezza
            
            <Circle
              className="relative inline-flex"
              sx={{ color: "#ff5757" }}
            />
          </header>
          <h2 className="text-lg font-semibold text-slate-800 mb-2">
            Pacchetto non Acquistato{" "}
          </h2>
          <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
            Vuoi acquistare questo pacchetto?
          </div>
          <div className="flex items-start">
            <button
              className="w-full m-5 p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
              id="Locker"
              onClick={() => askForArea("Sicurezza")}
            >
              <Lock id="LockerUP" />
              SBLOCCA{" "}
            </button>
          </div>
        </div>
        
        <div className="grow">
          
        </div>
      </div>
    )

    
  }
  
  {
    sessionStorage.getItem("F") === "1" ? (
      
      <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white  rounded-md border border-slate-200">
        <div className="px-5 pt-5">
          <header className="flex justify-between items-start mb-2">
            
            Formazione
            
            <Circle
              className="relative inline-flex"
              sx={{ color: "#51E838" }}
            />
          </header>
          <h2 className="text-lg font-semibold text-slate-800 mb-2">
            % Andamento Formazione
          </h2>
          <div className="flex justify-center mb-8 mt-8">
            <CircularProgressWithLabel
              variant="determinate"
              value={valFormazione}
              size={150}
            />
          </div>
        </div>
        
        <div className="grow">
          
        </div>
      </div>
    ) : (
      <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-grey hover:bg-white hover:cursor-pointer transition duration-300   rounded-md border border-slate-200">
        <div className="px-5 pt-5">
          <header className="flex justify-between items-start mb-2">
            
            Formazione
            
            <Circle
              className="relative inline-flex"
              sx={{ color: "#ff5757" }}
            />
          </header>
          <h2 className="text-lg font-semibold text-slate-800 mb-2">
            Pacchetto non Acquistato{" "}
          </h2>
          <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
            Vuoi acquistare questo pacchetto?
          </div>
          <div className="flex items-start">
            <button
              className="w-full m-5 p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
              id="Locker"
              onClick={() => askForArea("Formazione")}
            >
              <Lock id="LockerUP" />
              SBLOCCA{" "}
            </button>
          </div>
        </div>
        
        <div className="grow">
          
        </div>
      </div>
    )

    
  }
  
</div> 
<div className="bg-primary-600 dark:bg-white p-4 sm:p-6 rounded-md mt-4">
  <div className="flex flex-row items-center">
    <h4 className="text-xl md:text-2xl text-white dark:text-primary-600 font-bold mb-1 mr-5 mt-2 my-auto">
      Hai bisogno di aiuto? Chiedi supporto ai nostri operatori!
    </h4>
    <button
      className="flex items-center p-2 text-white bg-primary-100  hover:bg-primary-200 transition duration-300 rounded-md h-20 md:h-16"
      id="Locker"
    >
      <span className="flex items-center p-1">
        <CalendarMonth />
        <p className="pl-3">ORGANIZZA UNA CALL </p>
      </span>
    </button>
  </div>
</div>
*/}
</div>)

}


export default HelpPage;