import axios from "axios";

export default axios.create({
  //baseURL: "http://localhost:3000/api/",
  baseURL: "https://mhisapi-wyh4dbquta-ew.a.run.app/api/",

  //baseURL:'http://localhost:8080/api/'
});

//https://web.millergroup.it/api/

//http://localhost:8080/api/
